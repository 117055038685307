import { NextRequest, NextResponse } from 'next/server';
import { GraphQLRequestClient } from '@sitecore-jss/sitecore-jss';
import { RuleContext } from 'lib/rules/rules';
import { Debug } from 'lib/constants/debug';
import { Plugin } from '..';
import config from 'temp/config';

class GeoLocationPlugin implements Plugin {
  _graphqlClient: GraphQLRequestClient;
  order = 10;

  constructor() {
    // NOTE: we provide native fetch for compatibility on Next.js Edge Runtime
    // (underlying default 'cross-fetch' is not currently compatible: https://github.com/lquixada/cross-fetch/issues/78)
    this._graphqlClient = new GraphQLRequestClient(config.graphQLEndpoint, {
      apiKey: config.sitecoreApiKey,
      debugger: Debug.personalization,
      fetch: fetch,
    });
  }

  async exec(context: RuleContext, _req: NextRequest, res?: NextResponse) {
    const stateOrProvicnce = res?.headers.get('x-ew-geo-state-province') || '';
    context.parameters['geoLocation.stateOrProvicnce'] = stateOrProvicnce;

    return context;
  }
}

export const geoLocationPlugin = new GeoLocationPlugin();
